<template>
    <div class="pb-44">
        <base-heading
            :title="$t('cybooks.paymentData.header.title')"
            :description="$t('cybooks.paymentData.header.description')"
        >
            <template #right>
                <div class="flex flex-row-reverse gap-3">
                    <base-button
                        size="sm"
                        variant="primary"
                        icon="line-icons:general:check"
                    >
                        {{ $t('cybooks.paymentData.header.button.save') }}
                    </base-button>
                    <base-button
                        size="sm"
                        variant="secondary"
                        @click="onCancel"
                    >
                        {{ $t('cybooks.paymentData.header.button.cancel') }}
                    </base-button>
                </div>
            </template>
        </base-heading>

        <base-section
            :title="$t('cybooks.paymentData.general.title')"
            :description="$t('cybooks.paymentData.general.description')"
        >
            <template #right>
                <div class="flex flex-col gap-3">
                    <form-text-input
                        :label="
                            $t('cybooks.paymentData.general.companyName.label')
                        "
                        v-model="companyName"
                        :placeholder="
                            $t(
                                'cybooks.paymentData.general.companyName.placeholder'
                            )
                        "
                    />
                    <div class="flex flex-row gap-3">
                        <form-text-input
                            :label="
                                $t(
                                    'cybooks.paymentData.general.firstName.label'
                                )
                            "
                            v-model="firstName"
                            :placeholder="
                                $t(
                                    'cybooks.paymentData.general.firstName.placeholder'
                                )
                            "
                        />
                        <form-text-input
                            :label="
                                $t('cybooks.paymentData.general.lastName.label')
                            "
                            :placeholder="
                                $t(
                                    'cybooks.paymentData.general.lastName.placeholder'
                                )
                            "
                            v-model="lastName"
                        />
                    </div>
                    <form-text-input
                        :label="$t('cybooks.paymentData.general.email.label')"
                        :placeholder="
                            $t('cybooks.paymentData.general.email.placeholder')
                        "
                        v-model="email"
                    />
                </div>
            </template>
        </base-section>

        <base-section
            :title="$t('cybooks.paymentData.address.title')"
            :description="$t('cybooks.paymentData.address.description')"
        >
            <template #right>
                <div class="flex flex-col gap-3">
                    <form-text-input
                        required
                        :label="$t('cybooks.paymentData.address.address.label')"
                        v-model="address"
                        :placeholder="
                            $t(
                                'cybooks.paymentData.address.address.placeholder'
                            )
                        "
                    />
                    <div class="flex flex-row gap-3">
                        <form-text-input
                            :label="
                                $t('cybooks.paymentData.address.postcode.label')
                            "
                            v-model="postcode"
                            :placeholder="
                                $t(
                                    'cybooks.paymentData.address.postcode.placeholder'
                                )
                            "
                        />
                        <form-text-input
                            :label="
                                $t('cybooks.paymentData.address.city.label')
                            "
                            v-model="city"
                            :placeholder="
                                $t(
                                    'cybooks.paymentData.address.city.placeholder'
                                )
                            "
                        />
                    </div>
                    <form-single-select
                        :label="$t('cybooks.paymentData.address.country.label')"
                        v-model="country"
                    ></form-single-select>
                </div>
            </template>
        </base-section>

        <base-section
            :title="$t('cybooks.paymentData.vat.title')"
            :description="$t('cybooks.paymentData.vat.description')"
        >
            <template #right>
                <div class="flex flex-col gap-3">
                    <form-text-input
                        :label="$t('cybooks.paymentData.vat.vatId.label')"
                        v-model="vatId"
                        :placeholder="
                            $t('cybooks.paymentData.vat.vatId.placeholder')
                        "
                    />
                </div>
            </template>
        </base-section>
    </div>
</template>
<script setup>
import { useField } from 'vee-validate'
import { useRouter } from 'vue-router'

const router = useRouter()
const { value: companyName } = useField('company_name')
const { value: firstName } = useField('firstName')
const { value: lastName } = useField('lastName')
const { value: email } = useField('email')
const { value: address } = useField('address')
const { value: postcode } = useField('postcode')
const { value: city } = useField('city')
const { value: country } = useField('country')
const { value: vatId } = useField('vat_id')

const onCancel = () => {
    router.push({ name: 'cybooks.contract.list' })
}
</script>
