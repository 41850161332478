<template>
    <div class="flex w-full flex-row items-center gap-5">
        <div class="text-lg font-medium text-gray-900">
            {{ $t('cybooks.contract.invoices.title') }}
        </div>
    </div>
    <data-grid
        :data-source="dataSource.data"
        :columns="columns"
        :loading="loadingSearch"
        :tableBorderless="true"
    >
        <template #column-invoices="{ item }">
            <div class="flex w-full flex-row justify-between gap-4">
                <div class="text-sm font-normal text-gray-900">
                    {{ item.invoices.name }}
                </div>
                <div class="flex flex-row gap-4">
                    <base-tooltip
                        dark
                        hover
                        :content="$t('cybooks.contract.invoices.tooltip.view')"
                    >
                        <base-button
                            class="cursor-pointer"
                            size="inherit"
                            variant="tertiary-gray"
                            @click="redirectToPaymentData"
                            :icon="item.invoices.iconView"
                        />
                    </base-tooltip>
                </div>
            </div>
        </template>
    </data-grid>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useApiFactory } from '@tenant/composables'
import { useRouter } from 'vue-router'

const router = useRouter()
const { useApiSearch } = useApiFactory('contact')
const { loading: loadingSearch, execute: executeSearch } = useApiSearch()
const dataSource = ref([])
const columns = [
    {
        property: 'invoices',
        label: '',
    },
]

onMounted(() => {
    getInvoicesList(1)
})
// TODO: AC-378 InvoicesList Api
const getInvoicesList = (page) => {
    executeSearch({ page }).then(() => {
        dataSource.value = {
            total: 7,
            data: [
                {
                    id: 1,
                    invoices: {
                        name: 'Invoice 92021-229722 - 04.10.2021',
                        iconView: 'line-icons:education:book-closed',
                        iconDownload: 'line-icons:general:download-01',
                    },
                },
                {
                    id: 2,
                    invoices: {
                        name: 'Invoice 92021-229722 - 04.10.2021',
                        iconView: 'line-icons:education:book-closed',
                        iconDownload: 'line-icons:general:download-01',
                    },
                },
                {
                    id: 3,
                    invoices: {
                        name: 'Invoice 92021-229722 - 04.10.2021',
                        iconView: 'line-icons:education:book-closed',
                        iconDownload: 'line-icons:general:download-01',
                    },
                },
                {
                    id: 4,
                    invoices: {
                        name: 'Invoice 92021-229722 - 04.10.2021',
                        iconView: 'line-icons:education:book-closed',
                        iconDownload: 'line-icons:general:download-01',
                    },
                },
                {
                    id: 5,
                    invoices: {
                        name: 'Invoice 92021-229722 - 04.10.2021',
                        iconView: 'line-icons:education:book-closed',
                        iconDownload: 'line-icons:general:download-01',
                    },
                },
                {
                    id: 6,
                    invoices: {
                        name: 'Invoice 92021-229722 - 04.10.2021',
                        iconView: 'line-icons:education:book-closed',
                        iconDownload: 'line-icons:general:download-01',
                    },
                },
                {
                    id: 7,
                    invoices: {
                        name: 'Invoice 92021-229722 - 04.10.2021',
                        iconView: 'line-icons:education:book-closed',
                        iconDownload: 'line-icons:general:download-01',
                    },
                },
            ],
        }
    })
}

const redirectToPaymentData = () => {
    router.push({
        name: 'documents.payment.data',
    })
}
</script>
