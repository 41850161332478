import { GUARD } from '@tenant/utils/constants'
import { isActive } from '@tenant/core/feature'

const routes = [
    isActive('FEATURE_FLAG_1163')
        ? {
              name: 'cybooks',
              path: '/cybooks',
              children: [
                  {
                      name: 'cybooks.contract.list',
                      path: 'contract',
                      component: () => import('./pages/contract-list.vue'),
                  },
                  {
                      name: 'documents.payment.data',
                      path: 'cybooks/contract/payment-data',
                      component: () =>
                          import('./pages/contract-payment-data.vue'),
                  },
              ],
          }
        : {},
]

export default {
    routes,
    meta: { layout: 'dashboard-layout', guard: GUARD.TENANT },
}
