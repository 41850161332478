<template>
    <div
        class="rounded-lg border border-solid border-gray-200 shadow-sm"
        v-if="paymentDataSource.data"
    >
        <div class="flex w-full flex-col items-start gap-6 p-3 sm:p-6">
            <div
                class="flex w-full flex-row items-center justify-between gap-5 border-b border-gray-200 p-0 pb-4"
            >
                <div class="text-lg font-medium text-gray-900">
                    {{ $t('cybooks.contract.paymentData.title') }}
                </div>
                <base-button
                    size="inherit"
                    variant="tertiary-gray"
                    icon="line-icons:general:edit-01"
                    @click="redirectToPaymentData"
                />
            </div>

            <template v-for="(item, key) in paymentDataSource.data" :key="key">
                <div class="flex flex-col justify-center gap-1 text-sm">
                    <div class="text-sm font-medium text-gray-900">
                        {{ $t('cybooks.contract.paymentData.vatId') }}
                    </div>
                    <div class="text-sm font-normal text-gray-500">
                        {{ item.vatId }}
                    </div>
                </div>

                <div class="flex flex-col justify-center gap-1">
                    <div class="text-sm font-medium text-gray-900">
                        {{ $t('cybooks.contract.paymentData.billingAddress') }}
                    </div>
                    <div
                        class="w-full text-sm font-normal leading-6 text-gray-500 sm:w-2/6"
                    >
                        <address class="not-italic">
                            {{ item.billingAddress }}
                        </address>
                    </div>
                </div>

                <div class="flex flex-col justify-center gap-1 text-sm">
                    <div class="text-sm font-medium text-gray-900">
                        {{ $t('cybooks.contract.paymentData.emailDelivery') }}
                    </div>
                    <div class="text-sm font-normal text-gray-500">
                        {{ item.EmailInvoiceDelivery }}
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useApiFactory } from '@tenant/composables'
import { useRouter } from 'vue-router'

const router = useRouter()
const { useApiSearch } = useApiFactory('contact')
const { execute: executeSearch } = useApiSearch()
const paymentDataSource = ref([])

onMounted(() => {
    getPaymentDataSource(1)
})
// TODO: AC-378 PaymentData By Id Api
const getPaymentDataSource = (page) => {
    executeSearch({ page }).then(() => {
        paymentDataSource.value = {
            total: 1,
            data: [
                {
                    id: 1,
                    vatId: 'AB1234567C',
                    billingAddress:
                        'SMK Shape & Shift LTD Sarah Kroll Vrysoudion 28 8560 Peyia Cypru',
                    EmailInvoiceDelivery: 'hello@shapeandshift.dev',
                },
            ],
        }
    })
}

const redirectToPaymentData = () => {
    router.push({
        name: 'documents.payment.data',
    })
}
</script>
