export default {
    cybooks: {
        sidebar: {
            label: 'My Cybooks',
        },
        contract: {
            sidebar: {
                label: 'Contract data',
            },
            list: {
                title: 'Contract data',
                description:
                    "Here you'll find information regarding payment information concerning your sevdesk contract.",
            },
            creditNotes: {
                title: 'Credit notes',
                notFound: 'No credit notes found',
                search: 'Search',
            },
            invoices: {
                title: 'Invoices',
                tooltip: {
                    view: 'Record invoice as expense',
                    download: 'Download invoice',
                },
            },
            paymentData: {
                title: 'Payment data',
                vatId: 'VAT ID',
                billingAddress: 'Billing address',
                emailDelivery: 'Email for invoice delivery',
            },
        },
        paymentData: {
            header: {
                title: 'Change payment data',
                description:
                    "Here you'll find information regarding payment information concerning your sevdesk contract.",
                button: {
                    save: 'Save',
                    cancel: 'Cancel',
                },
            },
            general: {
                title: 'General information',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                companyName: {
                    label: 'Company name',
                    placeholder: 'SMK Shape & Shift LTD',
                },
                firstName: {
                    label: 'First name',
                    placeholder: 'Sarah',
                },
                lastName: {
                    label: 'Last name',
                    placeholder: 'Kroll',
                },
                email: {
                    label: 'E-mail address for billing',
                    placeholder: 'hello@shapeandshift.dev',
                },
            },
            address: {
                title: 'Address',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                address: {
                    label: 'Address *',
                    placeholder: 'Vrysoudion 28',
                },
                postcode: {
                    label: 'Postcode',
                    placeholder: '8560',
                },
                city: {
                    label: 'City',
                    placeholder: 'Peyia',
                },
                country: {
                    label: 'Country',
                },
            },
            vat: {
                title: 'VAT information',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                vatId: {
                    label: 'VAT ID',
                    placeholder: 'AB1234567C',
                },
            },
        },
    },
}
