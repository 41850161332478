import { isActive } from '@tenant/core/feature'

const navigation = [
    {
        id: 'sas-cybooks',
        path: '',
        label: 'cybooks.sidebar.label',
        icon: 'line-icons:general:info-square',
        position: 90,
    },
    ...(isActive('FEATURE_FLAG_1163')
        ? [
              {
                  id: 'cybooks.contract.list',
                  path: 'cybooks.contract.list',
                  label: 'cybooks.contract.sidebar.label',
                  parent: 'sas-cybooks',
                  position: 10,
              },
          ]
        : []),
]

export default navigation
